<template>
  <div class="news-details">
    {{content}} {{this.$router.history.current.params.id}}
  </div>
</template>
<script>
export default {
  data(){
    return {
      content:'abc'
    }
  },
  mounted(){
    console.log(this.$router)
  }
}
</script>